import { render, staticRenderFns } from "./currentCallerInfo.vue?vue&type=template&id=01de05fb&scoped=true&"
import script from "./currentCallerInfo.vue?vue&type=script&lang=js&"
export * from "./currentCallerInfo.vue?vue&type=script&lang=js&"
import style0 from "./currentCallerInfo.vue?vue&type=style&index=0&id=01de05fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01de05fb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBottomSheet,VCardText,VDivider,VListItem,VNavigationDrawer})
