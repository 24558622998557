import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import lt from 'vuetify/es5/locale/lt'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { lt },
        current: 'lt',
      },    
});
