<template>
  <v-bottom-sheet v-model="dialog" scrollable>
    <v-card>
      <v-card-title class="headline" v-if="productNotFound">
        <div
          style="padding-left:1em; padding-right:1em; color:#fff;"
          class="error"
        >
          Prekė nerasta
        </div>
      </v-card-title>
      <v-card-title class="headline" v-else>
        <div style="padding-left:0.5em">{{ product.title }}</div>
      </v-card-title>
      <div class="barcode-preview" style="margin-bottom:2em; text-align:center">
        <!-- <img :src="imgSrc" style="max-width:88%; max-height:250px" /> -->
        <div style="width:100%;text-align:center">
          Barkodas: {{ lastResult }}
        </div>
      </div>
      <v-card-text v-if="product">
        <v-col style="font-size:18px">
          <div>
            <div style="padding-bottom:1em">
              Likutis sandely: {{ product.qty ? product.qty : 0 }} vnt
            </div>
            <div style="padding-bottom:1em">Kaina: {{ product.price }} eur</div>
            <v-select
              :disabled="!product.qty"
              :items="getCount"
              filled
              label="Pasirinkti kiekį"
              v-model="amount"
            ></v-select>
          </div>
        </v-col>
      </v-card-text>
      <v-list>
        <v-list-item
          v-show="!tile.disabled"
          v-for="tile in tiles"
          :key="tile.title"
          @click="$emit(tile.method, tile.value)"
        >
          <v-list-item-avatar>
            <v-avatar size="32px" tile>
              <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title :style="tile.style">{{
            tile.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },
    imgSrc: {
      type: String,
    },
    productNotFound: {
      type: Boolean,
    },
    lastResult: {
      type: String,
    },
  },
  data() {
    return {
      dialog: true,
      amount: null,
    };
  },
  mounted() {},
  computed: {
    tiles() {
      return [
        {
          icon: "mdi-plus-thick",
          color: "#014C40",
          style: { fontWeight: "bold", fontSize: "18px" },
          title: `Pridėti ${this.amount} vnt.`,
          method: "add-to-cart",
          value: this.amount,
          disabled: !this.amount,
        },
        {
          icon: "mdi-reload",
          color: "#001E19",
          title: "Skenuoti iš naujo",
          method: "scan-again",
        },
        // {
        //   icon: "mdi-window-close",
        //   title: "Uždaryti (jokio veiksmo)",
        //   method: "close",
        // },
      ];
    },
    getCount() {
      let array = [];
      for (let index = 1; index <= this.product.qty; index++) {
        array.push(index);
      }
      return array;
    },
  },
  methods: {},
};
</script>

<style></style>
