/* webpackPrefetch: true */
const login = () =>
  import(/* webpackChunkName: "login" */ "./loginScreen");
const calls = () =>
  import(/* webpackChunkName: "calls" */ "./menuComponents/calls");      
const homeMenu = () =>
  import(/* webpackChunkName: "home" */ "./homeMenu");
// const purposeSelect = () =>
//   import(/* webpackChunkName: "home" */ "./purposeSelect");
const scan = () =>
  import(/* webpackChunkName: "scan" */ "./menuComponents/scan");
const cart = () =>
  import(/* webpackChunkName: "cart" */ "./menuComponents/cart");      
const products = () =>
  import(/* webpackChunkName: "products" */ "./menuComponents/productsTable");  
const purchases = () =>
  import(/* webpackChunkName: "purchases" */ "./menuComponents/purchases");    
// const currentCarts = () =>
//   import(/* webpackChunkName: "currentCarts" */ "./menuComponents/currentCarts");  
  
const routes = [
  // {
  //   path: "/",
  //   name: "purposeSelect",
  //   component: purposeSelect,
  // },
  {
    path: "/",
    name: "homeMenu",
    component: homeMenu,
  },   
  // {
  //   path: "/current-carts",
  //   name: "currentCarts",
  //   component: currentCarts,
  // },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/calls",
    name: "calls",
    component: calls,
  },
  {
    path: "/products",
    name: "products",
    component: products,
  }, 
  {
    path: "/purchases",
    name: "purchases",
    component: purchases,
  },   
  {
    path: "/scan",
    name: "scan",
    component: scan,
  },
  {
    path: "/cart",
    name: "cart",
    component: cart,
  },                      
];
export default routes;
