import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    peer: null,
    user: null,
    token: null,
    operator: null,
    appVersion: null,
    fastScan: false,
    showFoundModal: false,
    currentStream: null,
    currentVideoTrack: null,
    videoStreamStatusOn: false,
    operators: null,
    callsNumber: null,
    currentCallerDeviceID: null,
    currentCaller: null,
    torchAllwaysOn: true,
    removeFromCartStart: false,
    loadingOverlay: false,
    callerID: null,
    purchases: null,
    products: null,
    vibrateInterval: null,
    userStream: null,
    currentCallKey: null,
    calls: null,
    messengerOpen: false,
    cartItemCount: null,
    cartContent: null,
    appliedDiscount: null,
    trackedStreams: []
},
  mutations: {
      toggleShowFoundModal(state, status){
        state.showFoundModal = status
      },
      setCurrentPeer(state, peer){
        state.peer = peer
      },
      setCurrentStream(state, stream){
        state.currentStream = stream
      },
      addStreamToTrack(state, stream){
        state.trackedStreams.push(stream)
      },
      removeAllStreams(state) {
        state.trackedStreams = []
      },
      updatePurchases(state, purchases) {
        state.purchases = purchases
      },
      toggleLoadingOverlay(state, status){
        state.loadingOverlay = status
      },
      setVideoStreamStatusOn(state, status){
        state.videoStreamStatusOn = status
      },
      toggleRemoveFromCartStart(state){
        state.removeFromCartStart = !state.removeFromCartStart
      },
      toggleTorchAllwaysOn(state){
        state.torchAllwaysOn = !state.torchAllwaysOn
      },
      setCurrentCalls(state, calls){
        state.calls = calls
      },
      setCurrentCallsNumber(state, length){
        state.callsNumber = length
      },
      toggleFastScan(state){
        state.fastScan = !state.fastScan
      },
      setUser(state, user){
        state.user = user
        state.userID = user.id
      },
      setUserToken(state, token){
        state.token = token
      },
      setProducts(state, products) {
        state.products = products
      },
      setCurrOperator(state, operator) {
        state.operator = operator
      },
      setOperators(state, operators){
        state.operators = operators
      },
      setCurrentVideoTrack(state, track){
        state.currentVideoTrack = track
      },
      setCurrentCaller(state, currentCaller) {
        if (!currentCaller) { state.currentCaller = null; return } 
        state.currentCaller = currentCaller
        state.callerID = currentCaller.id ? currentCaller.id : currentCaller;
      },
      setCurrentCallerDeviceID(state, DeviceID){
        state.currentCallerDeviceID = DeviceID
      },
      setCartItemsCount(state, total){
        state.cartItemCount = total
      },
      updateCartContent(state, content){
          state.cartContent = content
      },
      setDiscountSize(state, content) {
        state.appliedDiscount = content
      },
      setVibrateInterval(state, interval){
        state.vibrateInterval = interval
      },
      setAppVersion(state, version){
        state.appVersion = version
      },
      setUserStream(state, stream){
        state.userStream = stream
      },
      openMessenger(state){
        state.messengerOpen = !state.messengerOpen;
      },
      setCurrentCallKey(state, key){
        state.currentCallKey = key
      }
  },
});

export default store;
