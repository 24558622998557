<template>
  <v-app style="user-select:none">
    <v-overlay :value="loadingOverlay" opacity="0.95">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="$root.snackbar"
      :color="$root.snackBarClass"
      style="z-index:1000000"
    >
      {{ $root.snackBarText }}
    </v-snackbar>

    <v-app-bar app color="" dark>
      <div class="d-flex align-center"></div>
      <div
        @click="$root.purpose ? $router.push('/menu') : $router.push('/')"
        style="margin-right:0.5em"
      >
        <v-icon>mdi-home</v-icon>
      </div>
      <div @click="selectOperator = true" v-if="operator">
        <div v-if="operator">Operatorius: {{ operator.name }}</div>
        <div v-else class="error">Nepriskirtas operatorius</div>
      </div>
      <div v-else>Kotas.lt operatoriaus prisijungimas</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <currentCallerInfo v-if="videoStreamStatusOn" @drop-call="dropCall" />
      <router-view v-if="user" />
      <loginScreen v-else />
    </v-main>
    <v-bottom-sheet
      :persistent="!operator"
      v-model="selectOperator"
      style="z-index:100000"
    >
      <v-sheet class="text-center">
        <div class="py-3">
          <operatorSelect @close="closeOperatorSelect" />
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <appFooter />
  </v-app>
</template>

<script>
import Vue from "vue";
import { db, messaging } from "./fire.js";
const axios = require("axios");
import routes from "./routes.js";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const router = new VueRouter({
  // mode: "history",
  routes,
  linkActiveClass: "active",
});
import currentCallerInfo from "./components/currentCallerInfo";
import operatorSelect from "./components/operatorSelect";
import loginScreen from "./loginScreen";
import appFooter from "./components/appFooter";
import { mapState } from "vuex";

export default {
  router,
  name: "App",
  components: {
    appFooter,
    loginScreen,
    operatorSelect,
    currentCallerInfo,
  },
  data: () => ({
    selectOperator: false,
  }),
  mounted() {
    this.$router.push("/");
    this.$store.commit("toggleLoadingOverlay", true);
    this.checkUser();
    this.requestNotificationPermission();
    this.initAxiosInstance();
    this.initCallWaiting();
    setTimeout(() => {
      this.requestPermission();
    }, 2000);
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      vibrateInterval: (state) => state.vibrateInterval,
      videoStreamStatusOn: (state) => state.videoStreamStatusOn,
      callsNumber: (state) => state.callsNumber,
      loadingOverlay: (state) => state.loadingOverlay,
      currentCaller: (state) => state.currentCaller,
      operators: (state) => state.operators,
      products: (state) => state.products,
      operator: (state) => state.operator,
      calls: (state) => state.calls,
      token: (state) => state.token,
    }),
  },
  watch: {
    user() {
      if (!this.operator) {
        setTimeout(() => {
          this.getAllOperators();
        }, 100);
        setTimeout(() => {
          if (!this.products) {
            this.getProductList();
          }
        }, 100);
      }
    },
    operator() {
      setInterval(() => {
        this.getPurchases();
      }, 10000);
    },
    $route() {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    requestPermission() {
      // [START messaging_request_permission]
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          this.initFirebaseMessaging();
          // TODO(developer): Retrieve a registration token for use with FCM.
          // ...
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
      // [END messaging_request_permission]
    },

    initFirebaseMessaging() {
      messaging
        .getToken({
          vapidKey:
            "BCyL3dAMqdqYWRAFnFb2q9r8rImwpn0lrPTdm8BzUFzX-l6s83Z-8PHIbIWxf8RUatcnINgf4CPdkXgiiIQymwI",
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            // Send the token to your server and update the UI if necessary
            // ...
            messaging.onMessage((payload) => {
              console.log("Message received. ", payload);
            });
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    },

    requestNotificationPermission() {
      Notification.requestPermission(function(status) {
        console.log("Notification permission status:", status);
      });
    },

    dropCall() {},

    async getProductList() {
      const result = await this.$root.axiosInstance.get("getProductList");
      this.$store.commit("setProducts", result.data.products);
    },

    initCallWaiting() {
      const self = this;
      db.ref(`signaling`).on("value", function(snapshot) {
        if (snapshot.numChildren() > self.callsNumber) {
          self.$root.notify("Skambina klientas!", "success");
          if (self.currentCaller) return;
          self.$root.playSound();
          self.vibrate();
          let times = 0;
          self.$store.commit(
            "setVibrateInterval",
            setInterval(() => {
              times++;
              self.vibrate();
              if (times === 5) clearInterval(self.vibrateInterval);
            }, 4000)
          );
        } else {
          clearInterval(self.vibrateInterval);
        }
        self.$store.commit("setCurrentCallsNumber", snapshot.numChildren());
        self.$store.commit("setCurrentCalls", snapshot.val());
      });
    },

    vibrate() {
      window.navigator.vibrate([
        100,
        30,
        100,
        30,
        100,
        30,
        500,
        100,
        30,
        100,
        30,
        100,
      ]);
    },

    logout() {
      const self = this;
      axios({
        method: "get",
        url: `${this.$root.baseUrl}/api/logout`,
        headers: {
          Authorization: "Bearer " + this.$root.getCookie("token"),
        },
      })
        .then(function() {
          self.$store.commit("setUser", null);
          self.$root.setCookie("user", "", 1);
          self.$root.setCookie("token", "", 1);
        })
        .catch(function(error) {
          // window.location = "/login";
          console.log(error);
        });
    },

    closeSnackBar() {
      this.$root.audio.pause();
      this.$root.audio.currentTime = 0;
      this.$root.snackbar = false;
    },

    initAxiosInstance() {
      const self = this;
      this.$root.axiosInstance = axios.create({
        baseURL: `${this.$root.baseUrl}/api/`,
        timeout: 5000,
        headers: {
          Authorization: "Bearer " + this.$root.getCookie("token"),
        },
      });
      this.$root.axiosInstance.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error.response.data.msg == "expired") {
            self.refreshToken();
          }
          return Promise.reject(error);
        }
      );
    },

    closeOperatorSelect() {
      if (!this.operator) {
        this.$root.notify("Būtina pasirinkti operatorių");
      }
      this.selectOperator = false;
    },

    async getAllOperators() {
      const response = await this.$root.axiosInstance.get("getAllOperators");
      this.$store.commit("setOperators", response.data.operators);
      this.selectOperator = true;
    },

    refreshToken() {
      const self = this;
      axios({
        method: "post",
        url: `${this.$root.baseUrl}/api/refresh`,
        headers: {
          Authorization: "Bearer " + this.$root.getCookie("token"),
        },
        data: {
          token: this.$root.getCookie("token"),
        },
      })
        .then(function(response) {
          if (response && response.data.user) {
            self.$root.setCookie("token", response.data.token, 3);
            self.$store.commit("setUser", response.data.user);
            self.$root.loggedIn = true;
            self.initAxiosInstance();
          }
        })
        .catch(function() {
          self.$root.notify(
            "Vartotojo sesija baigėsi. Prisijunkite iš naujo",
            "error"
          );
          self.$router.push("/login");
        });
    },

    async getPurchases() {
      const result = await this.$root.axiosInstance.get(
        `getPurchasesForOperator/${this.operator.id}`
      );
      if (
        this.purchases &&
        this.purchases.length < result.data.purchases.length
      ) {
        this.$root.playPurchaseSound();
      }
      this.$store.commit("updatePurchases", result.data.purchases);
    },

    checkUser() {
      const self = this;
      if (this.$root.getCookie("token")) {
        axios({
          method: "get",
          url: `${this.$root.baseUrl}/api/get-user`,
          headers: {
            Authorization: "Bearer " + this.$root.getCookie("token"),
          },
        })
          .then(function(response) {
            if (response && response.data.user && response.data.operator) {
              self.$store.commit("setUser", response.data.user);
              self.$root.loggedIn = true;
              self.initAxiosInstance();
              self.$store.commit("toggleLoadingOverlay", false);
            }
          })
          .catch(function(error) {
            self.$store.commit("toggleLoadingOverlay", false);
            if (error.response.data.msg == "expired") {
              self.refreshToken();
            }
          })
          .then(() => {
            self.$store.commit("toggleLoadingOverlay", false);
          });
      } else {
        self.initAxiosInstance();
      }
    },
  },
};
</script>

<style lang="scss">
body {
  background: #000;
}
.v-data-table {
  tr {
    td {
      font-size: 16px !important;
    }
  }
}
.v-expansion-panel-content__wrap {
  padding: 0;
}
.icon.active {
  background: #014c40;
  border-radius: 50%;
  padding: 1em;
}
.v-dialog__content {
  z-index: 10000002 !important;
}
@import "./scanner.scss";
</style>
