<template>
  <div
    @click="$emit('on-click')"
    v-tooltip="tooltip"
    style="position:relative;width:40px; height:40px; padding:2em; line-height:0; display:flex; align-items: center; justify-content: center;"
  >
    <div
      :style="{
        backgroundColor: color,
        padding: lessPadding ? '0.5em' : '1.5em',
      }"
      style="padding:1.5em; position:absolute; border-radius: 100%; display:flex; align-items: center; justify-content: center;"
    >
      <div class="icon" :class="{ active: active }" v-html="icons[icon]"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    lessPadding: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#014C40",
    },
  },
  data() {
    return {
      chat:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>',
      filter:
        '<svg viewBox="0 0 400 400"> <defs> <filter id="red-glow" filterUnits="userSpaceOnUse" x="-50%" y="-50%" width="200%" height="200%"> <!-- blur the text at different levels--> <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur5"/> <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur10"/> <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur20"/> <feGaussianBlur in="SourceGraphic" stdDeviation="30" result="blur30"/> <feGaussianBlur in="SourceGraphic" stdDeviation="50" result="blur50"/> <!-- merge all the blurs except for the first one --> <feMerge result="blur-merged"> <feMergeNode in="blur10"/> <feMergeNode in="blur20"/> <feMergeNode in="blur30"/> <feMergeNode in="blur50"/> </feMerge> <!-- recolour the merged blurs red--> <feColorMatrix result="red-blur" in="blur-merged" type="matrix" values="1 0 0 0 0 0 0.06 0 0 0 0 0 0.44 0 0 0 0 0 1 0" /> <feMerge> <feMergeNode in="red-blur"/> <!-- largest blurs coloured red --> <feMergeNode in="blur5"/> <!-- smallest blur left white --> <feMergeNode in="SourceGraphic"/> <!-- original white text --> </feMerge> </filter> </defs> <text x="200" y="200" text-anchor="middle">RED</text> </svg>',
      icons: {
        barcodeOff:
          '<svg style="width:44px;height:44px" viewBox="0 0 24 24"> <path fill="currentColor" d="M20.84 22.73L16 17.89V18H14V15.89L12 13.89V18H11V12.89L10 11.89V18H7V8.89L6 7.89V18H5V6.89L1.11 3L2.39 1.73L7 6.34L10 9.34L11 10.34L12 11.34L14 13.35V13.34L16 15.34V15.35L17 16.35V16.34L18.66 18H18.65L22.11 21.46L20.84 22.73M16 6H14V10.8L16 12.8V6M12 6H11V7.8L12 8.8V6M20 6H17V13.8L20 16.8V6M22 18V6H21V17.8L21.2 18H22M10 6H9.2L10 6.8V6M2 18H4V6H2V18Z" /> </svg>',
        search:
          '<svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        cart:
          '<svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>',
        expand:
          '<svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize"><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path></svg>',
        disconnect:
          '<svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-off"><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg>',
        camera:
          '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.4375 6.09375H15.4375C16.2995 6.09375 17.1261 6.43616 17.7356 7.04565C18.3451 7.65515 18.6875 8.4818 18.6875 9.34375V19.0938C18.6875 19.3092 18.6019 19.5159 18.4495 19.6683C18.2972 19.8206 18.0905 19.9062 17.875 19.9062H4.875C4.01305 19.9062 3.1864 19.5638 2.5769 18.9543C1.96741 18.3449 1.625 17.5182 1.625 16.6562V6.90625C1.625 6.69076 1.7106 6.4841 1.86298 6.33173C2.01535 6.17935 2.22201 6.09375 2.4375 6.09375V6.09375Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18.6875 11.375L24.375 8.125V17.875L18.6875 14.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        scan:
          '<svg width="26" height="26"  aria-hidden="true" focusable="false" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4 6h2v12H4V6m3 0h1v12H7V6m2 0h3v12H9V6m4 0h1v12h-1V6m3 0h2v12h-2V6m3 0h1v12h-1V6M2 4v4H0V4a2 2 0 0 1 2-2h4v2H2m20-2a2 2 0 0 1 2 2v4h-2V4h-4V2h4M2 16v4h4v2H2a2 2 0 0 1-2-2v-4h2m20 4v-4h2v4a2 2 0 0 1-2 2h-4v-2h4z" fill="#fff"/></svg>',
        rotate:
          '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-ccw"><polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path></svg>',
        microphone:
          '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13 2.4375H13C14.0774 2.4375 15.1108 2.86551 15.8726 3.62738C16.6345 4.38925 17.0625 5.42256 17.0625 6.5V13C17.0625 14.0774 16.6345 15.1108 15.8726 15.8726C15.1108 16.6345 14.0774 17.0625 13 17.0625H13C12.4665 17.0625 11.9382 16.9574 11.4453 16.7533C10.9525 16.5491 10.5046 16.2499 10.1274 15.8726C9.75014 15.4954 9.4509 15.0475 9.24674 14.5547C9.04258 14.0618 8.9375 13.5335 8.9375 13V6.49999C8.9375 5.42255 9.36551 4.38924 10.1274 3.62738C10.8892 2.86551 11.9226 2.4375 13 2.4375V2.4375Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13 20.3125V23.5625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.2677 13.8125C20.0676 15.6001 19.2158 17.2513 17.875 18.4504C16.5343 19.6496 14.7986 20.3125 12.9998 20.3125C11.201 20.3125 9.46534 19.6496 8.12456 18.4504C6.78378 17.2513 5.93196 15.6001 5.73193 13.8125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
        micOff:
          '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic-off"><line x1="1" y1="1" x2="23" y2="23"></line><path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path><path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg>',
      },
    };
  },
};
</script>

<style>
.action-icon {
  width: 16px;
  height: 16px;
}
</style>
