<template>
  <v-list>
    <v-list-item
      v-for="tile in tiles"
      :key="tile.title"
      @click="$emit('init-method', tile.method)"
    >
      <v-list-item-avatar>
        <v-avatar size="32px" tile>
          <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>{{ tile.title }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    tiles: {
      type: Array,
    },
  },
};
</script>

<style></style>
