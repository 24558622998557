<template>
  <v-row style="z-index:100000; position:absolute; left:0; top:0; width:106%;">
    <v-col cols="12" sm="12" md="6" class="elevation-0">
      <v-toolbar
        style="float: right; overflow: inherit;"
        class="elevation-2"
        short
        outlined
        rounded
        shaped
        collapse
        flat
      >
        <!-- <v-btn v-if="scanIsActive" icon @click="$emit('reinit')">
            <v-icon>mdi-reload</v-icon>
          </v-btn> -->
        <!-- <v-btn v-if="!mini" icon @click="$emit('scan')">
            <v-icon>mdi-barcode-scan</v-icon>
          </v-btn> -->
        <!-- <v-btn icon @click="$emit('zoom')">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn> -->
        <v-btn v-if="scanIsActive" icon @click="$emit('torch')">
          <v-icon>mdi-flashlight</v-icon>
        </v-btn>
        <!-- <v-spacer></v-spacer> -->
        <v-btn icon @click="showSettings = !showSettings">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn v-if="!mini" icon @click="$emit('search')">
          <v-icon>mdi-table-search</v-icon>
        </v-btn>
        <v-badge
          bottom
          offset-x="30"
          :content="getCartItemsNumber ? getCartItemsNumber : null"
          :value="getCartItemsNumber ? getCartItemsNumber : null"
          overlap
        >
          <v-btn icon @click="$emit('cart')">
            <v-icon>mdi-cart-variant</v-icon>
          </v-btn>
        </v-badge>
      </v-toolbar>
      <v-col v-if="showSettings" style="background:#fff">
        <v-switch
          v-model="torchAllwaysOn"
          label="Skenuojant įjungti blykstę"
        ></v-switch>
        <v-switch
          v-model="fastScan"
          label="Greitas skenavimas"
          hint="Didesnė klaidų tikimybė"
          persistent-hint
        ></v-switch>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    scanIsActive: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSettings: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userID: (state) => state.userID,
      operators: (state) => state.operators,
      showFoundModal: (state) => state.showFoundModal,
      cartContent: (state) => state.cartContent,
      cartItemCount: (state) => state.cartItemCount,
      products: (state) => state.products,
      operator: (state) => state.operator,
      token: (state) => state.token,
    }),
    getCartItemsNumber() {
      if (!this.cartContent) return;
      let total = 0;
      for (let itemKey in this.cartContent) {
        total += this.cartContent[itemKey].qty;
      }
      return total;
    },
    fastScan: {
      /* By default get() is used */
      get() {
        return this.$store.state.fastScan;
      },
      /* We add a setter */
      set() {
        this.$store.commit("toggleFastScan");
        this.$emit("apply-settings");
      },
    },
    torchAllwaysOn: {
      /* By default get() is used */
      get() {
        return this.$store.state.torchAllwaysOn;
      },
      /* We add a setter */
      set() {
        this.$store.commit("toggleTorchAllwaysOn");
      },
    },
  },
};
</script>

<style>
.v-toolbar.v-toolbar--collapsed {
  max-width: 100% !important;
}
</style>
