var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed top-0 left-0 h-screen w-full",staticStyle:{"overflow-y":"hidden"}},[_c('v-navigation-drawer',{staticStyle:{"z-index":"100000","opacity":"0.9"},attrs:{"absolute":"","right":"","width":"100%;"},model:{value:(_vm.cartDrawer),callback:function ($$v) {_vm.cartDrawer=$$v},expression:"cartDrawer"}},[_c('v-list-item',[(_vm.showCart)?_c('cart',{on:{"drop-call":_vm.drop,"close":function($event){_vm.cartDrawer = false}}}):(_vm.showSearch)?_c('productsTable',{attrs:{"showClose":""},on:{"close-products":_vm.hideSearch}}):_vm._e(),_c('v-divider')],1)],1),_c('v-card-text',{staticClass:"fullscreen"},[_c('div',{staticClass:"main-call-videos"},[_c('scanToolbar',{attrs:{"scanIsActive":_vm.scanIsActive},on:{"search":_vm.search,"cart":_vm.openCart,"scan":_vm.scan,"torch":_vm.toggleTorch}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.scanIsActive && _vm.currentCallEvents && _vm.currentCallEvents.userStreamOn
        ),expression:"\n          !scanIsActive && currentCallEvents && currentCallEvents.userStreamOn\n        "}],class:{
          main: _vm.mainVideo == 'caller-video',
          'not-main': _vm.mainVideo == 'my-stream-video',
        }},[_c('video',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableWithResetPosition),expression:"draggableWithResetPosition"}],staticClass:"shadow-md",class:{
            main: _vm.mainVideo == 'caller-video',
            'not-main': _vm.mainVideo == 'my-stream-video',
          },staticStyle:{"max-width":"100%"},attrs:{"id":"video-stream","autoplay":"","playsinline":""}})]),(_vm.scanIsActive)?_c('section',{staticClass:"container",attrs:{"id":"container"}},[_c('div',{staticClass:"viewport",staticStyle:{"position":"relative"},attrs:{"id":"interactive"}},[_c('div',{staticClass:"detectionArea",style:(_vm.detectionArea)}),_c('video',{attrs:{"id":"interactive","autoplay":"true","preload":"auto"}}),_c('canvas',{staticClass:"drawingBuffer",on:{"click":_vm.changeDetectionArea}})])]):_vm._e(),_c('video',{directives:[{name:"show",rawName:"v-show",value:(!_vm.scanIsActive),expression:"!scanIsActive"}],class:{
          main: _vm.mainVideo == 'my-stream-video',
          'not-main': _vm.mainVideo == 'caller-video',
          'facing-user': _vm.facingMode == 'user',
        },staticStyle:{"max-width":"100%"},attrs:{"id":"my-stream-video","muted":"","autoplay":"","playsinline":""},domProps:{"muted":true}})],1),(!_vm.scanIsActive)?_c('div',{staticClass:"toolbar",staticStyle:{"display":"flex","justify-content":"space-around","width":"100%","margin-bottom":"2em"}},_vm._l((_vm.actions),function(action){return _c('actionIcon',{key:action,attrs:{"active":action.active,"icon":action.icon,"tooltip":action.tooltip,"color":action.color},on:{"on-click":function($event){return _vm.initAction(action.action)}}})}),1):_c('div',{staticClass:"toolbar",staticStyle:{"display":"flex","justify-content":"space-around","width":"100%","margin-bottom":"2em"}},[_c('actionIcon',{attrs:{"lessPadding":false,"active":false,"icon":"barcodeOff","color":"red"},on:{"on-click":function($event){return _vm.initAction('scan')}}})],1)]),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('callFinishSelection',{attrs:{"tiles":_vm.tiles},on:{"init-method":_vm.initMethod}})],1),(_vm.showFoundModal)?_c('foundItemCard',{attrs:{"imgSrc":_vm.imgSrc,"lastResult":_vm.lastResult,"productNotFound":_vm.productNotFound,"product":_vm.product},on:{"add-to-cart":_vm.addToCart,"scan-again":_vm.reinit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }