<template>
  <div
    class="fixed top-0 left-0 h-screen w-full"
    style="overflow-y: hidden; overflow-y: hidden;"
  >
    <!-- v-if="activeTime > 10" -->
    <!-- <div
      v-if="activeTime > 6"
      style="z-index:10000000; font-size:20px; display: flex; justify-content: center; align-items: center; color:#fff; position:absolute;left:0;top:0;width:100vw;height:100vh; background:rgba(0,0,0,0.8); display:flex;"
    >
      Laukiame vartotojo
    </div> -->

    <v-navigation-drawer
      v-model="cartDrawer"
      absolute
      right
      width="100%;"
      style="z-index:100000; opacity:0.9;"
    >
      <v-list-item>
        <cart v-if="showCart" @drop-call="drop" @close="cartDrawer = false" />
        <productsTable
          showClose
          v-else-if="showSearch"
          @close-products="hideSearch"
        />
        <v-divider></v-divider>
      </v-list-item>
    </v-navigation-drawer>

    <v-card-text class="fullscreen">
      <div class="main-call-videos">
        <scanToolbar
          :scanIsActive="scanIsActive"
          @search="search"
          @cart="openCart"
          @scan="scan"
          @torch="toggleTorch"
        />
        <!--  -->
        <!-- Client stream area -->
        <!--  -->
        <div
          v-show="
            !scanIsActive && currentCallEvents && currentCallEvents.userStreamOn
          "
          :class="{
            main: mainVideo == 'caller-video',
            'not-main': mainVideo == 'my-stream-video',
          }"
        >
          <video
            v-draggable="draggableWithResetPosition"
            class="shadow-md"
            :class="{
              main: mainVideo == 'caller-video',
              'not-main': mainVideo == 'my-stream-video',
            }"
            id="video-stream"
            autoplay
            playsinline
            style="max-width:100%"
          ></video>
        </div>

        <!--  -->
        <!-- Scanner area -->
        <!--  -->
        <section v-if="scanIsActive" id="container" class="container">
          <div id="interactive" class="viewport" style="position:relative">
            <div class="detectionArea" :style="detectionArea"></div>
            <video id="interactive" autoplay="true" preload="auto"></video
            ><canvas
              @click="changeDetectionArea"
              class="drawingBuffer"
            ></canvas>
          </div>
        </section>

        <!--  -->
        <!-- Operator video area -->
        <!--  -->
        <video
          v-show="!scanIsActive"
          :class="{
            main: mainVideo == 'my-stream-video',
            'not-main': mainVideo == 'caller-video',
            'facing-user': facingMode == 'user',
          }"
          id="my-stream-video"
          muted
          autoplay
          playsinline
          style="max-width:100%"
        ></video>
        <!--  -->
      </div>

      <!--  -->
      <!-- Bottom video controls -->
      <!--  -->

      <div
        class="toolbar"
        v-if="!scanIsActive"
        style="display:flex; justify-content: space-around; width:100%;margin-bottom: 2em"
      >
        <actionIcon
          :key="action"
          :active="action.active"
          :icon="action.icon"
          :tooltip="action.tooltip"
          @on-click="initAction(action.action)"
          :color="action.color"
          v-for="action of actions"
        />
      </div>
      <div
        v-else
        class="toolbar"
        style="display:flex; justify-content: space-around; width:100%;margin-bottom: 2em"
      >
        <actionIcon
          :lessPadding="false"
          :active="false"
          icon="barcodeOff"
          @on-click="initAction('scan')"
          color="red"
        />
      </div>
      <!--  -->
    </v-card-text>

    <v-bottom-sheet v-model="sheet">
      <callFinishSelection :tiles="tiles" @init-method="initMethod" />
    </v-bottom-sheet>
    <foundItemCard
      v-if="showFoundModal"
      @add-to-cart="addToCart"
      :imgSrc="imgSrc"
      :lastResult="lastResult"
      :productNotFound="productNotFound"
      @scan-again="reinit"
      :product="product"
    />
  </div>
</template>

<script>
import { db } from "../fire.js";
// import Quagga from "quagga";
import { Draggable } from "draggable-vue-directive";
import Quagga from "@ericblade/quagga2";
import foundItemCard from "../components/foundItemCard";
import callFinishSelection from "../components/callFinishSelection";
import scanToolbar from "../components/scanToolbar";
import actionIcon from "./actionIcon";
import { mapState } from "vuex";
import cart from "../menuComponents/cart";
import productsTable from "../menuComponents/productsTable";
import { differenceInSeconds } from "date-fns";

export default {
  directives: {
    Draggable,
  },
  components: {
    cart,
    actionIcon,
    foundItemCard,
    scanToolbar,
    productsTable,
    callFinishSelection,
  },
  data() {
    return {
      draggableWithResetPosition: { resetInitialPos: false },
      soundEnabled: true,
      scanning: false,
      activeTime: null,
      currentCallEvents: { userStreamOn: true },
      showScanner: true,
      showReallyBlock: false,
      controlsDisabled: false,
      torchOn: false,
      codeArray: [],
      sheet: false,
      productNotFound: false,
      imgSrc: null,
      canvas: null,
      scanIsActive: false,
      showCart: false,
      showSearch: false,
      cartDrawer: false,
      product: null,
      mainVideo: "my-stream-video",
      facingMode: "user",
      fullscreen: true,
      expanded: true,
      currentCallerInterval: null,
    };
  },
  mounted() {
    this.watchCurrentCallEvents();
    this.currentCallerInterval = setInterval(() => {
      this.getTimeAgoInSeconds();
    }, 5000);
  },

  computed: {
    tiles() {
      return [
        {
          icon: "mdi-phone-hangup",
          color: "green",
          title: "Fiksuoti krepšelį ir baigti pokalbį",
          method: "finalize",
        },
        // {
        //   icon: "mdi-phone-hangup",
        //   color: "red",
        //   title: "Baigti pokalbį dabar",
        //   method: "drop",
        // },
        {
          icon: "mdi-account-cancel",
          color: "red",
          title: this.showReallyBlock
            ? "Tikrai blokuoti?"
            : "Blokuoti vartotoją",
          method: this.showReallyBlock ? "blockUser" : "showReallyBlock",
        },
        { icon: "", title: "" },
        {
          icon: "mdi-window-close",
          title: "Uždaryti (jokio veiksmo)",
          method: "closeSheet",
        },
      ];
    },
    getCartItemsNumber() {
      if (!this.cartContent) return;
      let total = 0;
      for (let itemKey in this.cartContent) {
        total += this.cartContent[itemKey].qty;
      }
      return total;
    },
    detectionArea() {
      return {
        top: "25%",
        right: "10%",
        left: "10%",
        bottom: "25%",
      };
    },
    ...mapState({
      peer: (state) => state.peer,
      currentStream: (state) => state.currentStream,
      user: (state) => state.user,
      userID: (state) => state.userID,
      callerID: (state) => state.callerID,
      currentVideoTrack: (state) => state.currentVideoTrack,
      currentCallerDeviceID: (state) => state.currentCallerDeviceID,
      trackedStreams: (state) => state.trackedStreams,
      showFoundModal: (state) => state.showFoundModal,
      cartContent: (state) => state.cartContent,
      cartItemCount: (state) => state.cartItemCount,
      operators: (state) => state.operators,
      operator: (state) => state.operator,
      products: (state) => state.products,
      fastScan: (state) => state.products,
      currentCallKey: (state) => state.currentCallKey,
      torchAllwaysOn: (state) => state.torchAllwaysOn,
      token: (state) => state.token,
    }),
    userActions() {
      return [
        {
          icon: "cart",
          tooltip: "Kliento krepšelis",
          active: this.showCart && this.cartDrawer,
          color: "red",
          count: this.getCartItemsNumber,
          action: "openCart",
        },
        {
          icon: "search",
          active: this.showSearch && this.cartDrawer,
          tooltip: "Prekių paieška",
          count: null,
          color: "red",
          action: "search",
        },
      ];
    },
    actions() {
      return [
        {
          icon: this.soundEnabled ? "microphone" : "micOff",
          action: "mute",
          tooltip: "Išjungti/įjungti garsą",
          color: this.soundEnabled ? "#014C40" : "#C33C22",
        },
        {
          icon: "rotate",
          tooltip: "Keisti kameras",
          action: "switchStreamCamera",
        },
        {
          icon: "disconnect",
          tooltip: "Baigti pokalbį",
          color: "#C33C22",
          action: "dropCall",
        },
        {
          icon: this.scanIsActive ? "barcodeOff" : "scan",
          tooltip: "Skenuoti prekę",
          color: this.scanIsActive ? "#C33C22" : "#001E19",
          action: "scan",
        },
      ];
    },
  },
  watch: {
    cartDrawer(val) {
      db.ref(`userCart/${this.callerID}/status`).set({
        scanning: val,
      });
    },
    currentCallKey() {
      this.watchCurrentCallEvents();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.currentCallerInterval);
  },
  methods: {
    watchCurrentCallEvents() {
      const self = this;
      db.ref(
        `currentCalls/${this.callerID}/${this.operator.id}/${this.currentCallKey}`
      ).on("value", function(snapshot) {
        self.currentCallEvents = snapshot.val();
      });
    },
    toggleZoom() {
      const self = this;
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      if (capabilities.zoom) {
        self.zoomedIn = !self.zoomedIn;
        track
          .applyConstraints({
            advanced: [{ zoom: self.zoomedIn ? 1 : 1.5 }],
          })
          .catch((e) => console.log(e));
      }
    },
    blockUser() {
      this.showReallyBlock = false;
      db.ref(`blockedUsers`).push({
        deviceID: this.currentCallerDeviceID,
      });
      this.drop();
    },
    initMethod(method) {
      if (method == "showReallyBlock") {
        this.showReallyBlock = true;
      } else {
        this.showReallyBlock = false;
        this[method]();
      }
    },
    toggleTorch(status) {
      const self = this;
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      if (capabilities.torch) {
        self.torchOn = !self.torchOn;
        track
          .applyConstraints({
            advanced: [{ torch: status ? status : self.torchOn }],
          })
          .catch((e) => console.log(e));
      }
    },
    closeSheet() {
      this.sheet = false;
    },
    finalize() {
      db.ref(`userCart/${this.callerID}/finished`).update({
        time: JSON.stringify(Date.now()),
        operator: this.operator.id,
      });
      this.$root.notify("Krepšelis buvo sėkmingai suformuotas", "success");
      this.drop();
    },
    getTimeAgoInSeconds() {
      this.interval = setInterval(() => {
        this.activeTime = differenceInSeconds(
          Date.now(),
          new Date(parseInt(this.currentCallEvents.activeTime))
        );
      }, 5000);
    },

    maybeSwitchViews() {
      this.mainVideo =
        this.mainVideo == "my-stream-video"
          ? "caller-video"
          : "my-stream-video";
    },

    initAction(action) {
      if (!this.controlsDisabled) {
        this[action]();
      }
      this.controlsDisabled = true;
      setTimeout(() => {
        this.controlsDisabled = false;
      }, 1000);
    },

    search() {
      this.showCart = false;
      this.showSearch = true;
      this.cartDrawer = true;
      db.ref(`userCart/${this.callerID}/status`).set({
        scanning: !this.scanning,
      });
      this.scanning = !this.scanning;
      if (this.scanIsActive) {
        this.scan();
      }
    },

    hideSearch() {
      this.showCart = false;
      this.showSearch = false;
      this.cartDrawer = false;
      db.ref(`userCart/${this.callerID}/status`).set({
        scanning: false,
      });
    },

    openCart() {
      if (!this.cartContent) {
        this.$root.notify("Krepšelis tuščias");
        return;
      }
      if (this.scanIsActive) {
        this.scan();
      }
      this.showSearch = false;
      this.showCart = true;
      this.cartDrawer = true;
    },

    playVideo() {
      var video = document.querySelector("#caller-video");
      video.play();
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },

    changeDetectionArea(event) {
      var bounds = event.target.getBoundingClientRect();
      this.x = event.clientX - bounds.left;
      this.y = event.clientY - bounds.top;
    },

    reinit() {
      this.codeArray = [];
      this.showScanner = true;
      this.productNotFound = false;
      this.product = null;
      this.$store.commit("toggleShowFoundModal", false);
      setTimeout(() => {
        this.startBarcodeScan();
      }, 500);
    },

    addToCart(qty) {
      let item = this.product;
      if (!this.callerID) {
        this.$root.notify("Krepšelis neaktyvus");
      }
      this.addOrUpdateQty(item, qty);
    },

    addOrUpdateQty(item, qty) {
      for (let it in this.cartContent) {
        if (!this.cartContent) break;
        if (this.cartContent[it].id == item.id) {
          db.ref(`userCart/${this.callerID}/items/${it}`).update({
            qty: this.cartContent[it].qty + qty,
          });
          this.successAdded();
          setTimeout(() => {
            this.reinit();
          }, 1000);
          return it;
        }
      }
      let itemCopy = JSON.parse(JSON.stringify(item));
      itemCopy.qty = qty;
      db.ref(`userCart/${this.callerID}/items/`).push(itemCopy);
      this.successAdded();
      setTimeout(() => {
        this.reinit();
      }, 1000);
    },

    successAdded() {
      this.$root.notify("Pridėjome prekes", "success");
      this.playSuccessSound();
      window.navigator.vibrate([100, 30, 100]);
    },

    playSuccessSound() {
      this.$root.audio = new Audio("/sounds/added.wav");
      this.$root.audio.play();
    },

    checkCapabilities() {
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      this.$root.capabilities = this.capabilities = capabilities;
    },

    async startBarcodeScan() {
      const self = this;
      this.facingMode = "environment";
      const videoDevices = await Quagga.CameraAccess.enumerateVideoDevices();
      Quagga.init(
        {
          focusMode: "continuous",
          inputStream: {
            name: "Live",
            type: "LiveStream",
            size: 1200,
            facingMode: "environment",
            target: document.querySelector("#interactive"),
            constraints: {
              width: 1400,
              height: 800,
              deviceId: videoDevices[videoDevices.length - 1].deviceId,
              // facingMode: "environment",
            },
            area: this.detectionArea,
          },
          frequency: 5,
          locator: {
            patchSize: "medium",
            halfSample: true,
          },
          decoder: {
            readers: [
              {
                format: "ean_reader",
                config: {},
              },
              {
                format: "upc_e_reader",
                config: {},
              },
              {
                format: "code_128_reader",
                config: {},
              },
              {
                format: "codabar_reader",
                config: {},
              },
            ],
          },
        },
        function(err) {
          if (err) {
            console.log(err);
            return;
          }
          console.log("Initialization finished. Ready to start");
          Quagga.start();
          self.checkCapabilities();
          if (self.torchAllwaysOn) {
            self.toggleTorch(true);
          }
          // self.replaceStream();
        }
      );
      this.initRegister();
    },

    replaceStream() {
      if (!this.peer) return;
      if (!this.peer.streams.length) return;
      const oldTrack = this.currentVideoTrack;
      const newTrack = Quagga.CameraAccess.getActiveTrack();
      this.peer.replaceTrack(oldTrack, newTrack, this.peer.streams[0]);
    },

    initMyVideo(stream) {
      const video = document.querySelector("#my-stream-video");
      video.srcObject = stream;
      video.play();
    },

    stop() {
      Quagga.stop();
    },

    checkIfCodeRepeats(code) {
      if (this.codeArray.length < (this.fastScan ? 4 : 12)) {
        this.codeArray.push(code);
      } else {
        return this.barcodeFoundMostFreq();
      }
    },

    barcodeFoundMostFreq() {
      var mf = 1;
      var m = 0;
      var item;
      for (var i = 0; i < this.codeArray.length; i++) {
        for (var j = i; j < this.codeArray.length; j++) {
          if (this.codeArray[i] == this.codeArray[j]) m++;
          if (mf < m) {
            mf = m;
            item = this.codeArray[i];
          }
        }
        m = 0;
      }
      return item;
    },

    async retrieveProductByCode(code) {
      this.productNotFound = false;
      const result = this.checkIfCodeRepeats(code);
      if (!result) return;
      let found = this.products.find((element) => element.ean == code);
      if (!found) {
        found = this.products.find((element) => element.ean == "0" + code);
      }
      if (!found) {
        found = this.products.find((element) => element.ean == "00" + code);
      }
      const foundWithoutZeroes = this.products.find(
        (element) => element.ean == code.replace(/^0+/, "")
      );
      this.product = foundWithoutZeroes ? foundWithoutZeroes : found;
      this.showScanner = false;
      window.navigator.vibrate([100, 30, 100, 30]);
      if (!this.product) {
        this.productNotFound = true;
      } else {
        this.productNotFound = false;
      }
      this.$store.commit("toggleShowFoundModal", true);
      Quagga.stop();
    },

    initRegister() {
      const self = this;
      Quagga.onProcessed(function(result) {
        var drawingCtx = Quagga.canvas.ctx.overlay,
          drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute("width")),
              parseInt(drawingCanvas.getAttribute("height"))
            );
            result.boxes
              .filter(function(box) {
                return box !== result.box;
              })
              .forEach(function(box) {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                  color: "green",
                  lineWidth: 2,
                });
              });
          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
              color: "#00F",
              lineWidth: 2,
            });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
              result.line,
              { x: "x", y: "y" },
              drawingCtx,
              { color: "red", lineWidth: 3 }
            );
          }
        }
      });
      Quagga.onDetected(function(result) {
        if (self.lastResult !== result.codeResult.code) {
          const canvas = Quagga.canvas.dom.image;
          self.imgSrc = canvas.toDataURL();
          self.lastResult = result.codeResult.code;
        }
        self.retrieveProductByCode(result.codeResult.code);
      });
    },

    scan() {
      if (!this.scanIsActive) {
        this.scanIsActive = true;
        this.pauseMyVideo();
        if (!this.currentVideoTrack)
          this.$store.commit(
            "setCurrentVideoTrack",
            this.peer.streams[0].getTracks()[1]
          );
        this.currentVideoTrack.stop();
        setTimeout(() => {
          this.startBarcodeScan();
          db.ref(`userCart/${this.callerID}/status`).set({
            scanning: true,
          });
        }, 200);
      } else {
        Quagga.stop();
        db.ref(`userCart/${this.callerID}/status`).set({
          scanning: false,
        });
        this.changeVideoCamStream();
        this.scanIsActive = false;
      }
    },

    pauseMyVideo() {
      const video = document.querySelector("#my-stream-video");
      if (video) {
        video.pause();
      }
    },

    listAllDevices() {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function(devices) {
          devices.forEach(function(device) {
            console.log(
              device.kind + ": " + device.label + " id = " + device.deviceId
            );
          });
        })
        .catch(function(err) {
          console.log(err.name + ": " + err.message);
        });
    },

    async changeVideoCamStream() {
      this.listAllDevices();
      const facingMode = (this.facingMode =
        this.facingMode == "user" ? "environment" : "user");
      if (!this.currentVideoTrack)
        this.$store.commit(
          "setCurrentVideoTrack",
          this.currentStream.getVideoTracks()[0]
        );
      this.pauseMyVideo();
      if (this.currentVideoTrack) {
        this.currentVideoTrack.stop();
      }
      this.currentVideoTrack.stop();
      this.stopLastStream();

      const videoDevices = await Quagga.CameraAccess.enumerateVideoDevices();

      setTimeout(() => {
        const constraints = {
          video: {
            width: { ideal: 1400 },
            height: { ideal: 800 },
          },
          // audio: true,
        };
        if (facingMode == "user") {
          constraints.video.facingMode = "user";
        } else {
          constraints.video.deviceId =
            videoDevices[videoDevices.length - 1].deviceId;
        }

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            const newTrack = stream.getVideoTracks()[0];
            this.currentStream.removeTrack(this.currentVideoTrack);
            this.currentStream.addTrack(newTrack);
            this.peer.replaceTrack(
              this.currentVideoTrack,
              stream.getVideoTracks()[0],
              this.currentStream
            );
            this.$store.commit("setCurrentVideoTrack", newTrack);
            this.initMyVideo(this.currentStream);
            this.$store.commit("addStreamToTrack", stream);
          })
          .catch((error) => {
            console.log(error.name + ": " + error.message);
          });
      }, 100);
    },
    switchStreamCamera() {
      this.changeVideoCamStream();
    },

    async mute() {
      const audioTrack = await this.currentStream.getAudioTracks();
      this.soundEnabled = audioTrack[0].enabled = !this.soundEnabled;
    },

    dropCall() {
      this.sheet = true;
    },

    stopLastStream() {
      this.trackedStreams[this.trackedStreams.length - 1]
        .getVideoTracks()
        .forEach((track) => {
          this.trackedStreams[this.trackedStreams.length - 1].removeTrack(
            track
          );
          track.stop();
        });
    },

    stopAllStreams() {
      this.trackedStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
    },

    drop() {
      this.pauseMyVideo();
      clearInterval(this.interval);
      this.currentStream.getTracks().forEach((track) => track.stop());
      this.stopAllStreams();
      setTimeout(() => {
        this.$store.commit("removeAllStreams");
        this.$store.commit("setCurrentStream", null);
        this.$store.commit("setCurrentCaller", null);
        this.$store.commit("setCurrentCallerDeviceID", null);
        this.peer.destroy();
        setTimeout(() => {
          this.$store.commit("setVideoStreamStatusOn", false);
        }, 1000);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-item-group {
  position: inherit !important;
}
.main-call-videos {
  video {
    width: 50%;
  }
  .toolbar {
    position: relative;
  }
}
.fullscreen {
  background: #000;
  #scanner-viewport {
    min-height: 100vh;
    canvas {
      min-height: 100vh;
      max-width: 100%;
    }
  }
}
#my-stream-video.facing-user {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.detectionArea {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.4);
    top: 0;
  }
}
.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  padding: 0 !important;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 1000;
  .not-main {
    position: absolute;
    width: 150px;
    height: auto;
    margin: 1em;
    margin-top: 3em;
    z-index: 100;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  #caller-video {
    margin-left: 0;
  }
  .main,
  #scanner-viewport video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .main {
    height: 100vh;
  }
  .toolbar {
    width: 100%;
    text-align: center;
    z-index: 10000;
    bottom: 10px;
    padding: 0.5em;
    padding-top: 1em;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
#scanner-viewport video {
  position: relative;
  width: 100%;
  height: 100%;
}
.v-overlay {
  z-index: 2001;
}
@media only screen and (max-width: 500px) {
  .main-call-videos {
    display: block !important;
  }
  video {
    width: 100% !important;
  }
  .fullscreen {
    .not-main {
      width: 150px !important;
    }
  }
}
</style>
