import Vue from 'vue'
import App from './App.vue'
import store from './store';
import { mapState } from "vuex";
import vuetify from './plugins/vuetify';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import './registerServiceWorker'

Vue.config.productionTip = false

var vm = new Vue({
  store,
  vuetify,
  data: {
    backendUrl: process.env.VUE_APP_BACKEND_FILES,
    loggedIn: false,
    snackbar: false,
    userVideoStream: null,
    appliedDiscount: null,
    capabilities: null,
    track: null,
    purpose: null,
    stream: null,
    snackBarText: null,
    snackBarClass: null,
    productScanMode: false,
    operatorSelect: false,
    operator: null,
    axiosInstance: null,
    deviceID: null,
    baseUrl: process.env.VUE_APP_BASE_URL,
    user: null,
    token: null,  
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      peer: (state) => state.peer,
      currentStream: (state) => state.currentStream,
      trackedStreams: (state) => state.trackedStreams,
      userStream: (state) => state.userStream,
      operator: (state) => state.operator,
      purchases: (state) => state.purchases,
      callerID: (state) => state.callerID,
      currentCaller: (state) => state.currentCaller,
    }),    
    getCartItemsNumber() {
      if (!this.cartContent) return;
      let total = 0;
      for (let itemKey in this.cartContent) {
        total += this.cartContent[itemKey].qty;
      }
      return total;
    },
    menu() {
    return [
      // {
      //   name: "Visi klientų krepšeliai",
      //   description: "Norint valdyti klientų krepšelius, skenuoti ar pridėti prekes per sąrašą, pirma reikia pasirinkti klientą, kurio pirkinius valdysite",
      //   icon: "mdi-cash-usd-outline",
      //   route: "current-carts",
      //   content: null,
      //   color: '#3F51B5',
      //   hideFooter: true
      // },      
      {
        name: "Laukiantys skambučiai",
        shortName: "Klientai",
        description: "Visi esami ir eilėje laukiantys klientai",
        content: this.$root.calls ? Object.keys(this.$root.calls).length : null,
        icon: "mdi-phone-outline",
        route: "calls",
        badgeColor: "red",
        color: '#385F73',
        disabled: this.getDisabledRules('calls')
      },
      {
        name: "Skenuoti prekę",
        shortName: "Skenuoti",
        description: "Skenuoti prekių barkodus arba rasti pagal ID. Prekės pridedamos į kliento krepšelį",
        icon: "mdi-barcode-scan",
        content: 0,
        route: "scan",
        color: '#952175',
        disabled: this.getDisabledRules('scan')
      },
      {
        name: "Kliento krepšelis",
        shortName: "Krepšelis",
        description: "Pridėti, pašalinti pirkinius. Galima pritaikyti nuolaidą.",
        icon: "mdi-cart",
        content: this.getCartItemsNumber,
        badgeColor: "green",
        route: "cart",
        color: '#4A148C',
        disabled: this.getDisabledRules('cart')
      },
      {
        name: "Visos prekės",
        shortName: "Prekės",
        description: "Visų prekių duomenų bazė su paprasta paieška",
        icon: "mdi-view-list-outline",
        content: 0,
        route: "products",
        color: '#ff9800',
        disabled: this.getDisabledRules('products')
      },
      {
        name: "Užsakymai ir operacijos",
        shortName: "Užsakymai",
        description: "Užsakytų prekių statusas. Spausdinti siuntimo etiketes.",
        icon: "mdi-cash-multiple",
        content: this.purchases ? this.purchases.length : null,
        route: "purchases",
        color: '#3F51B5',
        disabled: this.getDisabledRules('products')
      },
      ]
    }
  },
  methods: {
    async getCurrentDeviceID() {
      // We recommend to call `load` at application startup.
      const fp = await FingerprintJS.load();
    
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get();

      // This is the visitor identifier:
      this.deviceID = result.visitorId;
    },
    setCookie(name, value, daysToLive) {
      var cookie = name + "=" + encodeURIComponent(value);
      if (typeof daysToLive === "number") {
        cookie += ";path=/; max-age=" + daysToLive * 24 * 60 * 60;
        document.cookie = cookie;
      }
    },
    getDisabledRules(code){
      if (code == 'calls') {
        if (this.$root.productScanMode) return true
      } else if (code == 'scan') {
        return false
        // if (!this.$root.currentCaller) return true
      } else if (code == 'cart') {
        if (!this.$root.currentCaller) return true
      } else if (code == 'products') {
        return false
      }
      return false
    },
    playPurchaseSound(){
      this.$root.audio = new Audio('/sounds/coin.wav');
      this.$root.audio.play();
      setTimeout(() => {
        this.$root.audio.pause();
      }, 6000);
    },
    playSound(){
      if (this.currentCaller) return
      this.$root.audio = new Audio('/sounds/ring.mp3');
      this.$root.audio.play();
      setTimeout(() => {
        this.$root.audio.pause();
      }, 6000);
    },
    toggleSnackBar(text, snackClass){
      this.snackBarText = text
      this.snackBarClass = snackClass
      this.snackbar = true
      setTimeout(() => {
        this.snackbar = false        
      }, 5000);
    },
    notify(text, snackClass = 'info'){
      this.toggleSnackBar(text, snackClass)
    },
    displayBrowserNotification(text) {
      if (Notification.permission == 'granted') {
        navigator.serviceWorker.getRegistration().then(function(reg) {
          reg.showNotification(text, {
            body: 'Test123!',
            sound: 'https://operator.kotas.lt/coin.wav',
            channel_id: '0000',
            vibrate: [200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200, 200, 100, 200, 100, 200, 100, 200],
          });
        });
      }
    },   
    getCookie(name) {
      var cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
        }
      }
      return null;
    }    
  },
  mounted(){
    this.getCurrentDeviceID()
  },
  render: h => h(App)
}).$mount('#app')

global.vm = vm