<template>
  <div>
    <v-btn v-if="showClose" class="info mt-4" @click="$emit('close-products')">
      Uždaryti
    </v-btn>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Ieškoti (pagal ID, pavadinimą arba barkodą)"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      light
      :search="search"
      :headers="headers"
      :items="products"
      disable-sort
      class="elevation-2"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          :disabled="!currentCaller"
          color="success"
          tile
          @click="addToCart(item)"
        >
          <v-icon left>
            mdi-cart
          </v-icon>
          Pridėti
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from "../fire.js";
import { mapState } from "vuex";

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    search: null,
    dialogDelete: false,
    headers: [
      {
        text: "Pavadinimas",
        align: "start",
        value: "title",
      },
      { text: "ID", value: "product_id" },
      { text: "Barkodas", value: "ean" },
      { text: "Kaina", value: "price" },
      { text: "Kiekis", value: "qty" },
      { text: "Pridėti į krepšelį", value: "actions" },
    ],
    editedIndex: -1,
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
      callerID: (state) => state.callerID,
      currentCaller: (state) => state.currentCaller,
      cartContent: (state) => state.cartContent,
      products: (state) => state.products,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  mounted() {
    db.ref(`userCart/${this.callerID}/status`).set({
      scanning: true,
    });
  },

  beforeDestroy() {
    db.ref(`userCart/${this.callerID}/status`).set({
      scanning: false,
    });
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {},

  methods: {
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addToCart(item) {
      if (!this.currentCaller) {
        this.$root.notify("Krepšelis neaktyvus");
        return;
      }
      this.addOrUpdateQty(item);
      this.$root.notify("Prekė pridėta");
      this.$emit("close-products");
      this.close();
    },

    addOrUpdateQty(item) {
      for (let it in this.cartContent) {
        if (!this.cartContent) break;
        if (this.cartContent[it].id == item.id) {
          db.ref(`userCart/${this.callerID}/items/${it}`).update({
            qty: this.cartContent[it].qty + 1,
          });
          return it;
        }
      }
      item.operator = this.$root.operator;
      let itemCopy = JSON.parse(JSON.stringify(item));
      itemCopy.qty = 1;
      db.ref(`userCart/${this.callerID}/items/`).push(itemCopy);
      db.ref(`userCart/${this.userID}/user`).set(this.currentCaller);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  padding-bottom: 2em;
}
</style>
