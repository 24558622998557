<template>
  <v-app style="z-index:1000000">
    <v-btn
      @click="$emit('close')"
      class="info mt-4 inline-block mb-2"
      style="display:inline-block"
    >
      Uždaryti
    </v-btn>
    <v-sheet
      v-if="!currentCaller || !cartContent"
      :color="`grey lighten-2`"
      style="height:100vh;justify-content:center;align-items:center;display:flex"
      align="center"
    >
      <div style="width:100%">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
        <div class="mt-2" style="width:100%">Krepšelis tuščias</div>
      </div>
    </v-sheet>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Pavadinimas
            </th>
            <th class="text-left">
              Kiekis
            </th>
            <th class="text-left">
              Kaina (su PVM)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            @click="
              selectedItem = item;
              itemKey = key;
              dialog = true;
              updateDialog();
            "
            v-for="(item, key) of cartContent"
            :key="item"
          >
            <td>{{ item.title }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ item.price }}</td>
          </tr>
        </tbody>
        <v-row class="pl-8 pt-8">
          <div v-if="appliedDiscount">
            Nuolaida: <b> {{ appliedDiscount }}%</b>
          </div>
          <br />
          <div style="font-weight:800; font-size:18px; width:100%">
            Viso (su PVM): {{ totalWithDiscount }} eur
          </div>
        </v-row>

        <v-row
          v-if="discounts && discounts.length"
          align="center"
          class="ml-2 mt-4"
        >
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="discount"
              outlined
              :items="discounts"
              item-text="percentage"
              item-value="code"
              label="Nuolaida (%)"
            ></v-select>
          </v-col>
        </v-row>
        <v-btn
          v-if="discounts && discounts.length"
          class="mt-0 ml-4"
          @click="applyDiscount"
          >Pritaikyti nuolaidą</v-btn
        >
      </template>
    </v-simple-table>
    <v-dialog v-if="showDialog" v-model="dialog" scrollable width="500">
      <v-card>
        <v-card-title class="grey lighten-2">
          {{ selectedItem.title }}
        </v-card-title>

        <v-card-text class="mt-4">
          <div style="display:flex; justify-content: space-between;">
            <div>
              Kiekis: <b>{{ selectedItem.qty }}</b>
            </div>
            <div>
              <v-icon
                aria-hidden="false"
                style="font-size:30px"
                @click="plusOne"
              >
                mdi-plus-circle-outline
              </v-icon>
              <v-icon
                aria-hidden="false"
                @click="minusOne"
                style="margin-left:1em;font-size:30px"
              >
                mdi-minus-circle-outline
              </v-icon>
            </div>
          </div>
          <div
            class="mt-8"
            style="display:flex; justify-content: space-between; text-align:left; color:red"
            @click="showOptions"
          >
            <div
              v-if="!removeFromCartStart"
              style="display:flex; justify-content: space-between; text-align:left; color:red"
            >
              <v-icon aria-hidden="false" color="red">
                mdi-trash-can
              </v-icon>
              <div class="ml-2">Pašalinti iš krepšelio</div>
            </div>
            <div v-else>
              <v-btn color="error" @click="removeFromCart">TAIP</v-btn>
              <v-btn
                color="success ml-4"
                @click="
                  showOptions;
                  dialog = false;
                "
                >NE</v-btn
              >
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Uždaryti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { db } from "../fire.js";
import { mapState } from "vuex";
const axios = require("axios");

export default {
  data() {
    return {
      dialog: false,
      discount: null,
      showDialog: true,
      prodDiscount: null,
      discounts: null,
      zeroDiscount: {
        id: 0,
        code: "NULIS",
        percentage: 0,
      },
      appliedDiscount: null,
      itemKey: null,
      trashIcon:
        '<svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>',
      selectedItem: {},
    };
  },
  mounted() {
    this.getDiscounts();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      callerID: (state) => state.callerID,
      removeFromCartStart: (state) => state.removeFromCartStart,
      currentCaller: (state) => state.currentCaller,
      cartContent: (state) => state.cartContent,
      products: (state) => state.products,
      operator: (state) => state.operator,
      token: (state) => state.token,
    }),
    total() {
      let price = 0;
      for (let itemKey in this.cartContent) {
        console.log(parseFloat(this.cartContent[itemKey].price));
        price +=
          parseFloat(this.cartContent[itemKey].price) *
          parseInt(this.cartContent[itemKey].qty);
      }
      console.log("price", price);
      return price;
    },
    totalWithDiscount() {
      return (this.total - (this.total * this.appliedDiscount) / 100).toFixed(
        2
      );
    },
    getCartItemsNumber() {
      if (!this.cartContent) return;
      let total = 0;
      for (let itemKey in this.cartContent) {
        total += this.cartContent[itemKey].qty;
      }
      return total;
    },
  },
  methods: {
    getDiscountSize(discount) {
      const dicountObj = this.discounts.find(
        (element) => element.code == discount
      );
      return dicountObj.percentage;
    },
    applyDiscountToProduct(val) {
      db.ref(`userCart/${this.callerID}/items/${this.itemKey}`).update({
        discount: val,
      });
      this.selectedItem.discount = val;
      this.$root.notify("Nuolaida pritaikyta");
      this.prodDiscount = null;
      this.updateDialog();
    },
    applyDiscount() {
      const discountSize = this.discounts.find(
        (element) => element.code == this.discount
      );
      console.log("this.discount", this.discount);
      console.log("this.discounts", this.discounts);
      console.log("discountSize", discountSize);
      db.ref(`userCart/${this.callerID}/discount`).update({
        code: this.discount,
      });
      this.appliedDiscount = discountSize.percentage;
    },
    showOptions() {
      this.$store.commit("toggleRemoveFromCartStart");
    },
    updateDialog() {
      this.showDialog = false;
      this.$nextTick(() => {
        this.showDialog = true;
        this.$nextTick(() => {});
      });
    },
    async getDiscounts() {
      const self = this;
      const result = await axios.get(`${this.$root.baseUrl}/get-discounts`);
      this.discounts = result.data.discounts;
      this.discounts.push(this.zeroDiscount);
      db.ref(`userCart/${this.callerID}/discount`).once("value", function(
        snapshot
      ) {
        if (!snapshot.val()) return;
        self.discount = snapshot.val().code;
        self.applyDiscount();
      });
    },
    plusOne() {
      db.ref(`userCart/${this.callerID}/items/${this.itemKey}`).update({
        qty: this.selectedItem.qty + 1,
      });
      this.selectedItem.qty++;
    },
    minusOne() {
      if (this.selectedItem.qty < 2) return;
      db.ref(`userCart/${this.callerID}/items/${this.itemKey}`).update({
        qty: this.selectedItem.qty - 1,
      });
      this.selectedItem.qty--;
    },
    removeFromCart() {
      this.showOptions();
      setTimeout(() => {
        db.ref(`userCart/${this.callerID}/items/${this.itemKey}`).remove();
        this.dialog = false;
        if (!this.getCartItemsNumber) this.$emit("close");
      }, 100);
    },
  },
};
</script>

<style lang="scss"></style>
