<template>
  <v-row style="text-align:left; padding-bottom:0;">
    <div
      class="pt-4 pl-8 pb-4 error"
      v-if="showErrorCode"
      style="width:100%; color:#fff; font-weight:bold; font-size:18px"
    >
      Kodas neteisingas!
    </div>
    <div class="w-full"></div>
    <v-header class="ml-6 mt-4 w-full" style="font-weight:bold">
      Pasirinkite operatorių
    </v-header>
    <div
      v-if="showPass"
      style="display: flex; flex-direction: row; width:100%; padding:1em 0"
    >
      <div style="margin:0 auto">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-complete="handleOnComplete"
        />
      </div>
    </div>
    <v-card style="width:100%; padding-left:1em" elevation="0">
      <v-list two-line>
        <template v-for="item in operators.slice(0, 6)">
          <v-list-item
            :class="{ active: selectedOperator == item.id }"
            :key="item.name"
            @click="selectOperator(item.id)"
          >
            <v-list-item-avatar
              color="indigo"
              size="48"
              style="text-align:center;justify-content: center;"
            >
              <span class="white--text headline">{{
                item.name.charAt(0)
              }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.descr"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-row>
</template>

<script>
import Vue from "vue";
import OtpInput from "@bachdgvn/vue-otp-input";
import { mapState } from "vuex";

Vue.component("v-otp-input", OtpInput);

export default {
  data: () => ({
    confirmPass: true,
    selectedOperator: -1,
    showErrorCode: false,
    showOperators: true,
    showPass: false,
    password: null,
  }),
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      callerID: (state) => state.callerID,
      products: (state) => state.products,
      operator: (state) => state.operator,
      operators: (state) => state.operators,
      token: (state) => state.token,
    }),
  },
  methods: {
    selectOperator(operatorID) {
      this.selectedOperator = operatorID;
      this.showPass = true;
    },
    errorCode() {
      this.showErrorCode = true;
      setTimeout(() => {
        this.showErrorCode = false;
      }, 2000);
    },
    handleOnComplete(val) {
      const found = this.operators.find((element) => element.password == val);
      this.handleClearInput();
      if (!found || (found && found.id != this.selectedOperator)) {
        this.errorCode();
        return;
      }
      this.select(found);
    },
    async getProductList() {
      const result = await this.$root.axiosInstance.get("getProductList");
      this.$store.commit("setProducts", result.data.products);
    },
    select(operator) {
      this.$store.commit("setCurrOperator", operator);
      this.getProductList();
      this.showPass = false;
      this.$emit("close");
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
};
</script>

<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.v-list-item.active {
  background: #5f6766;
  .v-list-item__title {
    color: #fff !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
