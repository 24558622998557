<template>
  <div>
    <v-row align="center" no-gutters style="height:90vh">
      <v-col align="center" align-self="center" style="margin: 0 2em">
        <v-card
          class="mx-auto"
          max-width="444"
          style="padding:2em"
          elevation="2"
          raised
          color="rgba(122,122,122,0.1)"
        >
          <form ref="form">
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              outlined
              label="El. paštas"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              outlined
              @click:append="show1 = !show1"
            >
            </v-text-field>
            <v-btn
              class="mr-4 green lighten-1"
              style="color:#fff"
              @click="login"
            >
              Prisijungti
            </v-btn>
          </form>
          <div style="padding-top:1em; color:#cecece">v.{{ appVersion }}</div>
        </v-card>
      </v-col>
    </v-row>
    <v-btn class="gray" @click="hardReload">
      Atnaujinti
    </v-btn>
  </div>
</template>

<script>
import { version } from "../package";
const axios = require("axios");
import { mapState } from "vuex";

export default {
  data() {
    return {
      email: null,
      password: null,
      appVersion: version,
      show1: false,
    };
  },
  mounted() {
    console.log("version", version);
    // this.$store.commit("setAppVersion", version);
    // if (this.appVersion != version) {
    // document.location.reload(true);
    // }
    // this.appVersion = version;
    setTimeout(() => {
      this.$store.commit("toggleLoadingOverlay", false);
    }, 2000);
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      appVersion: (state) => state.appVersion,
    }),
  },
  methods: {
    reloadAfterClear() {
      {
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach(async (name) => {
              await caches.delete(name);
            });
          });
        }
      }
    },
    hardReload() {
      if (this.reloadAfterClear) window.location.reload();
    },
    reset() {
      this.$refs.form.reset();
    },
    failedLoginAlert() {
      this.$root.notify("Prisijungti nepavyko", "error");
    },
    successAlert() {
      this.$root.notify("Sėkmingai prisijungėte", "success");
      setTimeout(() => {
        this.$root.operatorSelect = true;
      }, 2000);
    },
    initAxiosInstance(token) {
      this.$root.axiosInstance = axios.create({
        baseURL: `${this.$root.baseUrl}/api/`,
        timeout: 5000,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    login() {
      const self = this;
      axios
        .post(`${this.$root.baseUrl}/api/login`, {
          email: this.email,
          password: this.password,
        })
        .then(function(response) {
          if (response.data.user) {
            self.$store.commit("setUser", response.data.user);
            self.$root.setCookie("token", response.data.token, 3);
            self.initAxiosInstance(response.data.token);
            self.successAlert();
          } else {
            self.failedLoginAlert();
          }
        })
        .catch(function(error) {
          self.failedLoginAlert();
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
