<template>
  <v-bottom-navigation
    v-model="value"
    background-color="gray"
    color="primary"
    height="52"
    dense
    fixed
    mandatory
    shift
    grow
  >
    <v-row align="center" justify="center" style="height:100%;margin:0">
      <v-badge
        v-show="!item.hideFooter && !item.disabled"
        style="height:100%"
        :key="item"
        :content="item.content"
        :value="item.content"
        v-for="item of $root.menu"
        :color="item.badgeColor"
      >
        <v-btn @click="goto(item.route)" :disabled="item.disabled">
          <span>{{ item.shortName }}</span>
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </v-badge>
    </v-row>
  </v-bottom-navigation>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style></style>
