// import Vue from 'vue'
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
// import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
// import 'firebase/messaging';

const databaseUrl = process.env.VUE_APP_FIREBASE_URL

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCyWSPY8GTUMVF9ywg31KwPVx3pw5oW-l8",
    authDomain: "videomessenger-a3869.firebaseapp.com",
    databaseURL: databaseUrl,
    projectId: "videomessenger-a3869",
    storageBucket: "videomessenger-a3869.appspot.com",
    messagingSenderId: "325238411621",
    appId: "1:325238411621:web:b2baff82acf0f6c5f8d18c",
    measurementId: "G-H852CYHYRS"
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  const db = firebase.database();
  // const messaging = firebase.messaging();

export { db }